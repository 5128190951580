import Model, { attr, hasMany } from '@ember-data/model';

export default class PartnerConfigModel extends Model {
  @attr partner_id;
  @attr('boolean', { defaultValue: false }) iframe_sport;
  @attr() iframe_secret_key;
  @attr('string') iframe_player_url;
  @attr('string') iframe_do_bet_url;
  @attr('number') casino_template_id;
  @attr('number') wheel_template_id;
  @attr('number') sports_template_id;
  @attr('string') default_language;
  @attr('number', { defaultValue: 0 }) odds_stream_live;
  @attr('number', { defaultValue: 0 }) odds_stream_line;
  @attr('number', { defaultValue: 10 }) sign_up_type;
  @attr('number', { defaultValue: 10 }) odds_accept_option;
  @attr('number') default_route;
  @attr('boolean', { defaultValue: false }) home_default_route;
  @attr('number') withdraw_request_approval_limit;
  @attr('string') curacao_license_key;
  @attr('image') curacao_image;
  @attr('boolean') curacao_show;
  @attr('string') backoffice_application;
  @attr('string') callback_url;
  @attr('boolean') show_promo_code;
  @attr('boolean') sign_up;
  @attr('boolean') have_live_stream;
  @attr('boolean') sports;
  @attr('boolean') use_cashout;
  @attr('boolean') strict_login_mode;
  @attr('string') strict_login_mode_background_desktop;
  @attr('string') strict_login_mode_background_mobile;
  @attr('string') click_send_username;
  @attr('string') click_send_api_key;
  @attr('string') africa_talking_username;
  @attr('string') africa_talking_api_key;
  @attr('string') footer_info;
  @attr('boolean', { defaultValue: true }) retail_withdraw_limit;
  @attr('string') customer_site_id;
  @attr('string') customer_api_key;
  @attr('boolean', { defaultValue: false }) customer_is_active;
  @attr('string') affiliate_host;
  @attr('string') affiliate_secret;
  @attr('string') affiliate_key;
  @attr('boolean', { defaultValue: false }) affiliate_is_active;
  @attr('number') slide_duration;
  @attr('number') sms_integration_type;
  @attr('number') site_wallet;
  @attr('number') cash_withdraw_type;
  @attr('number') retail_limit_type;
  @attr('number') payment_template_id;
  @attr('boolean') pwa_active;
  @attr('boolean') withdraw_verification;
  @attr('boolean') cashbox_mode;
  @attr('string') pwa_manifest_url;
  @attr('string') mailchimp_key;
  @attr('string') sendpost_key;
  @attr('string') email;
  @attr('string') transactional_email;
  @attr('number') mail_send_type;
  @attr('string') payment_center_token;
  @attr('string') short_name_code;
  @attr('boolean') globalpass;
  @attr('string') globalpass_push_token;
  @attr('number', { defaultValue: 0 }) welcome_bonus_amount;
  @attr('number', { defaultValue: 0 }) welcome_free_spins;
  @attr('boolean', { defaultValue: false }) registration_sms_verification;
  @attr('boolean') loqate_enabled;
  @attr('string') loqate_fields;
  @attr('string') loqate_api_key;
  @attr('string') image_bucket_url;
  @attr('string') vivo_operator_id;
  @attr('string') vivo_passkey;
  @attr('string') ogs_operator_id;
  @attr('string') ogs_access_key;
  @attr('string') outcombet_cert_path;
  @attr('string') spinomenal_id;
  @attr('string') spinomenal_pass_key;
  @attr('string') oryx_url;
  @attr('string') oryx_wallet;
  @attr('string') oryx_username;
  @attr('string') oryx_password;
  @attr('string') cellxpert_username;
  @attr('string') cellxpert_password;
  @attr('string') cellxpert_domain;
  @attr('string') captcha_site_key;
  @attr('string') captcha_secret_key;
  @attr('boolean') captcha_is_active;
  @attr('number') user_inactivity_timeout;
  @attr('string') zoho_chat;

  @hasMany('signup-type', { async: false, inverse: null }) signupTypes;

  @attr('string') geoip_restriction_type;

  toJSON() {
    return {
      partner_id: this.partner_id,
      iframe_sport: this.iframe_sport,
      iframe_secret_key: this.iframe_secret_key,
      iframe_player_url: this.iframe_player_url,
      iframe_do_bet_url: this.iframe_do_bet_url,
      casino_template_id: this.casino_template_id,
      wheel_template_id: this.wheel_template_id,
      sports_template_id: this.sports_template_id,
      default_language: this.default_language,
      odds_stream_live: this.odds_stream_live,
      odds_stream_line: this.odds_stream_line,
      sign_up_type: this.sign_up_type,
      odds_accept_option: this.odds_accept_option,
      default_route: this.default_route,
      home_default_route: this.home_default_route,
      withdraw_request_approval_limit: this.withdraw_request_approval_limit,
      curacao_license_key: this.curacao_license_key,
      curacao_image: this.curacao_image,
      curacao_show: this.curacao_show,
      backoffice_application: this.backoffice_application,
      callback_url: this.callback_url,
      show_promo_code: this.show_promo_code,
      sign_up: this.sign_up,
      have_live_stream: this.have_live_stream,
      sports: this.sports,
      use_cashout: this.use_cashout,
      strict_login_mode: this.strict_login_mode,
      strict_login_mode_background_desktop: this.strict_login_mode_background_desktop,
      strict_login_mode_background_mobile: this.strict_login_mode_background_mobile,
      click_send_username: this.click_send_username,
      click_send_api_key: this.click_send_api_key,
      africa_talking_username: this.africa_talking_username,
      africa_talking_api_key: this.africa_talking_api_key,
      footer_info: this.footer_info,
      retail_withdraw_limit: this.retail_withdraw_limit,
      customer_site_id: this.customer_site_id,
      customer_api_key: this.customer_api_key,
      customer_is_active: this.customer_is_active,
      affiliate_host: this.affiliate_host,
      affiliate_secret: this.affiliate_secret,
      affiliate_key: this.affiliate_key,
      affiliate_is_active: this.affiliate_is_active,
      slide_duration: this.slide_duration,
      sms_integration_type: this.sms_integration_type,
      site_wallet: this.site_wallet,
      cash_withdraw_type: this.cash_withdraw_type,
      retail_limit_type: this.retail_limit_type,
      payment_template_id: this.payment_template_id,
      pwa_active: this.pwa_active,
      withdraw_verification: this.withdraw_verification,
      cashbox_mode: this.cashbox_mode,
      pwa_manifest_url: this.pwa_manifest_url,
      mailchimp_key: this.mailchimp_key,
      sendpost_key: this.sendpost_key,
      email: this.email,
      transactional_email: this.transactional_email,
      mail_send_type: this.mail_send_type,
      payment_center_token: this.payment_center_token,
      short_name_code: this.short_name_code,
      globalpass: this.globalpass,
      globalpass_push_token: this.globalpass_push_token,
      geoip_restriction_type: this.geoip_restriction_type,
      loqate_enabled: this.loqate_enabled,
      loqate_fields: this.loqate_fields,
      loqate_api_key: this.loqate_api_key,
      welcome_free_spins: this.welcome_free_spins,
      welcome_bonus_amount: this.welcome_bonus_amount,
      registration_sms_verification: this.registration_sms_verification,
      image_bucket_url: this.image_bucket_url,
      vivo_operator_id: this.vivo_operator_id,
      vivo_passkey: this.vivo_passkey,
      ogs_operator_id: this.ogs_operator_id,
      ogs_access_key: this.ogs_access_key,
      outcombet_cert_path: this.outcombet_cert_path,
      spinomenal_id: this.spinomenal_id,
      spinomenal_pass_key: this.spinomenal_pass_key,
      oryx_url: this.oryx_url,
      oryx_wallet: this.oryx_wallet,
      oryx_username: this.oryx_username,
      oryx_password: this.oryx_password,
      cellxpert_username: this.cellxpert_username,
      cellxpert_password: this.cellxpert_password,
      cellxpert_domain: this.cellxpert_domain,
      captcha_secret_key: this.captcha_secret_key,
      captcha_site_key: this.captcha_site_key,
      captcha_is_active: this.captcha_is_active,
      user_inactivity_timeout: this.user_inactivity_timeout,
      zoho_chat: this.zoho_chat,
    };
  }
}
